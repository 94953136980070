exports.components = {
  "component---src-components-case-study-page-index-jsx": () => import("./../../../src/components/caseStudyPage/index.jsx" /* webpackChunkName: "component---src-components-case-study-page-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ar-vr-js": () => import("./../../../src/pages/ar-vr.js" /* webpackChunkName: "component---src-pages-ar-vr-js" */),
  "component---src-pages-branding-js": () => import("./../../../src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-capabilities-js": () => import("./../../../src/pages/capabilities.js" /* webpackChunkName: "component---src-pages-capabilities-js" */),
  "component---src-pages-capabilitiesdeck-js": () => import("./../../../src/pages/capabilitiesdeck.js" /* webpackChunkName: "component---src-pages-capabilitiesdeck-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-information-js": () => import("./../../../src/pages/cookie-information.js" /* webpackChunkName: "component---src-pages-cookie-information-js" */),
  "component---src-pages-development-js": () => import("./../../../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-managed-services-js": () => import("./../../../src/pages/managed-services.js" /* webpackChunkName: "component---src-pages-managed-services-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-salesforce-js": () => import("./../../../src/pages/salesforce.js" /* webpackChunkName: "component---src-pages-salesforce-js" */),
  "component---src-pages-ux-js": () => import("./../../../src/pages/ux.js" /* webpackChunkName: "component---src-pages-ux-js" */),
  "component---src-pages-virtualoffices-js": () => import("./../../../src/pages/virtualoffices.js" /* webpackChunkName: "component---src-pages-virtualoffices-js" */),
  "component---src-pages-widgets-js": () => import("./../../../src/pages/widgets.js" /* webpackChunkName: "component---src-pages-widgets-js" */)
}

